import { useResumeStore } from "@/client/stores/resume";
import { t } from "@lingui/macro";
import { getSectionIcon } from "../shared/section-icon";
import { cn } from "@reactive-resume/utils";

type Language = "en" | "de";

interface LanguageSelectorProps {
  selectedLanguage: Language;
  onLanguageChange: (language: Language) => void;
}

export const LanguageSelector = ({ selectedLanguage, onLanguageChange }: LanguageSelectorProps) => {
  return (
    <div className="flex items-center gap-4">
      <label className="flex items-center gap-2 cursor-pointer">
        <div className="relative flex items-center">
          <input
            type="radio"
            className="peer sr-only"
            name="language"
            value="en"
            checked={selectedLanguage === "en"}
            onChange={(e) => onLanguageChange(e.target.value as Language)}
          />
          <div className="relative flex items-center justify-center w-5 h-5">
            <div
              className={cn(
                "absolute inset-0 m-auto w-5 h-5 rounded-full border-2",
                selectedLanguage === "en" ? "border-black" : "border-gray-400",
              )}
            />
            <div className="absolute inset-0 m-auto w-5 h-5 rounded-full border border-transparent" />
            {selectedLanguage === "en" && (
              <div className="absolute inset-0 m-auto w-2.5 h-2.5 rounded-full bg-black" />
            )}
          </div>
        </div>
        <span className="text-sm">English</span>
      </label>

      <label className="flex items-center gap-2 cursor-pointer">
        <div className="relative flex items-center">
          <input
            type="radio"
            className="peer sr-only"
            name="language"
            value="de"
            checked={selectedLanguage === "de"}
            onChange={(e) => onLanguageChange(e.target.value as Language)}
          />
          <div className="relative flex items-center justify-center w-5 h-5">
            <div
              className={cn(
                "absolute inset-0 m-auto w-5 h-5 rounded-full border-2",
                selectedLanguage === "de" ? "border-black" : "border-gray-400",
              )}
            />
            <div className="absolute inset-0 m-auto w-5 h-5 rounded-full border border-transparent" />
            {selectedLanguage === "de" && (
              <div className="absolute inset-0 m-auto w-2.5 h-2.5 rounded-full bg-black" />
            )}
          </div>
        </div>
        <span className="text-sm">German</span>
      </label>
    </div>
  );
};

export const LanguageSection = () => {
  const setValue = useResumeStore((state) => state.setValue);
  const language = useResumeStore((state) => state.resume.data.metadata.language);

  return (
    <section id="language" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon("language")}
          <h2 className="line-clamp-1 text-3xl font-medium">{t`Language`}</h2>
        </div>
      </header>

      <main className="grid gap-y-4">
        <div className="p-3">
          <LanguageSelector
            selectedLanguage={language}
            onLanguageChange={(language) => setValue("metadata.language", language)}
          />
        </div>
      </main>
    </section>
  );
};
