import { ScrollArea, Separator } from "@reactive-resume/ui";
import { useRef } from "react";

import { Copyright } from "@/client/components/copyright";

import { useCampusStore } from "@/client/stores/campus";
import { ExportSection } from "./sections/export";
import { LanguageSection } from "./sections/language";
import { LayoutSection } from "./sections/layout";
import { NotesSection } from "./sections/notes";
import { PageSection } from "./sections/page";
import { TemplateSection } from "./sections/template";
import { ThemeSection } from "./sections/theme";
import { TypographySection } from "./sections/typography";

export const RightSidebar = () => {
  const containterRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoView = (selector: string) => {
    const section = containterRef.current?.querySelector(selector);
    section?.scrollIntoView({ behavior: "smooth" });
  };

  const enableLanguages = useCampusStore((state) => state.metadata.enableLanguages);

  return (
    <div className="flex bg-secondary">
      <ScrollArea orientation="vertical" className="h-screen flex-1 pb-16 lg:pb-0">
        <div ref={containterRef} className="grid gap-y-6 p-6 @container/right">
          <ExportSection />
          <Separator />
          <TemplateSection />
          <Separator />
          {enableLanguages && (
            <>
              <LanguageSection />
              <Separator />
            </>
          )}
          <LayoutSection />
          <Separator />
          <TypographySection />
          <Separator />
          <ThemeSection />
          <Separator />
          <PageSection />
          {/* <Separator />
          <SharingSection /> */}
          {/* <Separator />
          <StatisticsSection /> */}
          <Separator />
          <NotesSection />
          {/* <Separator />
          <InformationSection /> */}
          <Separator />
          <Copyright className="text-center" />
        </div>
      </ScrollArea>
    </div>
  );
};
