import { Outlet } from "react-router-dom";

export const DashboardLayout = () => {
  return (
    <>
      {/* <Button asChild size="lg" variant="secondary" className="m-4">
        <Link to={CAMPUS_JOB_SEARCH_URL}>{t`Back to Campus`}</Link>
      </Button> */}

      <main className="bg-white pl-12 py-1">
        <Outlet />
      </main>
    </>
  );
};
