import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

const COLOR_SCHEME_QUERY = "(prefers-color-scheme: dark)";

type Theme = "system" | "dark" | "light";

interface UseThemeOutput {
  theme: Theme;
  isDarkMode: boolean;
  toggleTheme: () => void;
  setTheme: Dispatch<SetStateAction<Theme>>;
}

export const useTheme = (): UseThemeOutput => {
  const isDarkMode = false;
  const [theme, setTheme] = useLocalStorage<Theme>("theme", "system");

  function toggleTheme() {
    const toggleDict: Record<Theme, Theme> = {
      light: "system",
      system: "dark",
      dark: "light",
    };

    setTheme((prevMode) => toggleDict[prevMode]);
  }

  return {
    theme,
    setTheme,
    isDarkMode,
    toggleTheme,
  };
};
