import { t } from "@lingui/macro";
import {
  CheckCircle,
  CircleNotch,
  CopySimple,
  DotsThree,
  FolderOpen,
  Lock,
  MinusCircle,
  PencilSimple,
  TrashSimple,
} from "@phosphor-icons/react";
import { ResumeDto } from "@reactive-resume/dto";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { useResumePreview } from "@/client/services/resume/preview";
import { useDialog } from "@/client/stores/dialog";

import { useUpdateDoneResume } from "@/client/services/resume/done";
import { ResumeActionsMenu } from "../../shared/actions-menu";
import { BaseCard } from "./base-card";
import { useCampusStore } from "@/client/stores/campus";

type Props = {
  resume: ResumeDto;
};

type LanguageFlagProps = {
  language: "en" | "de";
};

const LanguageFlag = ({ language }: LanguageFlagProps) => {
  const getFlagEmoji = (lang: LanguageFlagProps["language"]) => {
    const countryMap = {
      en: "🇬🇧", // UK flag
      de: "🇩🇪", // German flag
    };

    return countryMap[lang];
  };

  return (
    <span className="inline-flex items-center justify-center text-1xl">
      {getFlagEmoji(language)}
    </span>
  );
};

export const ResumeCard = ({ resume }: Props) => {
  const navigate = useNavigate();
  const { open } = useDialog<ResumeDto>("resume");
  const { open: lockOpen } = useDialog<ResumeDto>("lock");
  const { toggleResumeDoneStatus } = useUpdateDoneResume();

  const onChangeDoneStatus = async () => {
    await toggleResumeDoneStatus(resume);
  };

  const resumeUpdatedAt = resume.updatedAt;
  const { url, loading } = useResumePreview(resume.id, resumeUpdatedAt);

  const lastUpdated = dayjs().to(resumeUpdatedAt);

  const onOpen = () => {
    navigate(`/builder/${resume.id}`);
  };

  const onUpdate = () => {
    open("update", { id: "resume", item: resume });
  };

  const onDuplicate = () => {
    open("duplicate", { id: "resume", item: resume });
  };

  const onLockChange = () => {
    lockOpen(resume.locked ? "update" : "create", { id: "lock", item: resume });
  };

  const onDelete = () => {
    open("delete", { id: "resume", item: resume });
  };

  const enableLanguages = useCampusStore((state) => state.metadata.enableLanguages);

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <BaseCard onClick={onOpen} className="space-y-0">
          <AnimatePresence presenceAffectsLayout>
            {loading && (
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <CircleNotch
                  size={64}
                  weight="thin"
                  opacity={0.5}
                  className="animate-spin self-center justify-self-center"
                />
              </motion.div>
            )}

            {!loading && url && (
              <motion.img
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                loading="lazy"
                alt={resume.title}
                className="size-full object-cover"
                src={url}
              />
            )}
          </AnimatePresence>

          <AnimatePresence>
            {resume.locked && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center bg-background/75 backdrop-blur-sm"
              >
                <Lock size={42} />
              </motion.div>
            )}
            {resume.done && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center bg-background/75 backdrop-blur-sm"
              >
                <CheckCircle size={42} weight="thin" />
              </motion.div>
            )}
          </AnimatePresence>

          <div
            className={cn(
              "absolute inset-x-0 bottom-0 z-10 flex justify-between space-y-0.5 p-4 pt-8",
              "bg-white",
            )}
          >
            {enableLanguages && (
              <div className="flex flex-row items-center space-x-4 justify-end">
                <LanguageFlag language={resume.data.metadata.language} />
                <div className="flex flex-col justify-end">
                  <h4 className="line-clamp-2 font-medium">{resume.title}</h4>
                  <p className="line-clamp-1 text-xs opacity-75">{t`Last updated ${lastUpdated}`}</p>
                </div>
              </div>
            )}

            <div>
              <ResumeActionsMenu resume={resume}>
                <DotsThree size={24} />
              </ResumeActionsMenu>
            </div>
          </div>
        </BaseCard>
      </ContextMenuTrigger>

      <ContextMenuContent>
        <ContextMenuItem onClick={onOpen}>
          <FolderOpen size={14} className="mr-2" />
          {t`Open`}
        </ContextMenuItem>
        <ContextMenuItem onClick={onUpdate}>
          <PencilSimple size={14} className="mr-2" />
          {t`Rename`}
        </ContextMenuItem>
        <ContextMenuItem onClick={onDuplicate}>
          <CopySimple size={14} className="mr-2" />
          {t`Duplicate`}
        </ContextMenuItem>
        {resume.done ? (
          <ContextMenuItem onClick={onChangeDoneStatus}>
            <MinusCircle size={14} className="mr-2" />
            {t`Clear Done status`}
          </ContextMenuItem>
        ) : (
          <ContextMenuItem onClick={onChangeDoneStatus}>
            <CheckCircle size={14} className="mr-2" />
            {t`Mark as Done`}
          </ContextMenuItem>
        )}
        {/* {resume.locked ? (
          <ContextMenuItem onClick={onLockChange}>
            <LockOpen size={14} className="mr-2" />
            {t`Unlock`}
          </ContextMenuItem>
        ) : (
          <ContextMenuItem onClick={onLockChange}>
            <Lock size={14} className="mr-2" />
            {t`Lock`}
          </ContextMenuItem>
        )} */}
        <ContextMenuSeparator />
        <ContextMenuItem onClick={onDelete} className="text-error">
          <TrashSimple size={14} className="mr-2" />
          {t`Delete`}
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};
