import { t } from "@lingui/macro";
import { Button, ScrollArea, Tabs, TabsContent } from "@reactive-resume/ui";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { PlayCircle } from "@phosphor-icons/react";

import { GridView } from "./_layouts/grid";
import { ExplainerVideo } from "../_components/explainer-video";

type Layout = "grid" | "list";

export const ResumesPage = () => {
  const [showExplainerVideo, setShowExplainerVideo] = useState(false);
  const openExplainerVideo = () => setShowExplainerVideo(true);
  const closeExplainerVideo = () => setShowExplainerVideo(false);

  useEffect(() => {
    window.parent?.postMessage({ type: "POPUP_STATE", isOpen: showExplainerVideo }, "*");

    return () => {
      window.parent?.postMessage({ type: "POPUP_STATE", isOpen: false }, "*");
    };
  }, [showExplainerVideo]);

  const [layout, setLayout] = useState<Layout>("grid");

  return (
    <>
      <Helmet>
        <title>
          {t`Resumes`} - {t`Reactive Resume`}
        </title>
      </Helmet>

      <Tabs
        value={layout}
        onValueChange={(value) => setLayout(value as Layout)}
        className="space-y-4"
      >
        <ScrollArea className="h-[calc(100vh)] lg:h-[calc(100dvh)] pr-12 pb-2">
          <div className="flex items-center justify-between">
            <motion.h1 className="text-3xl font-light tracking-tight">{t`Resumes`}</motion.h1>
            <Button size="md" variant="borderless" className="gap-2" onClick={openExplainerVideo}>
              <PlayCircle /> {t`Explainer video`}
            </Button>
          </div>

          <ExplainerVideo show={showExplainerVideo} onClose={closeExplainerVideo} />

          <TabsContent value="grid">
            <GridView />
          </TabsContent>
        </ScrollArea>
      </Tabs>
    </>
  );
};
