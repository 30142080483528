import { TooltipProvider } from "@reactive-resume/ui";
import { QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";

import { helmetContext } from "../constants/helmet";
import { queryClient } from "../libs/query-client";
import { DialogProvider } from "./dialog";
import { LocaleProvider } from "./locale";
import { ThemeProvider } from "./theme";
import { Toaster } from "./toaster";
import { CampusProvider } from "./campus";
import { useEffect } from "react";
import { useDialogStore } from "../stores/dialog";

export const Providers = () => (
  <LocaleProvider>
    <HelmetProvider context={helmetContext}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <TooltipProvider>
            <DialogProvider>
              <CampusProvider>
                <InnerStateExposerTracker>
                  <Outlet />
                </InnerStateExposerTracker>
                <Toaster />
              </CampusProvider>
            </DialogProvider>
          </TooltipProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </LocaleProvider>
);

const InnerStateExposerTracker = (children?: any) => {
  const location = useLocation();
  useEffect(() => {
    window.parent?.postMessage({ type: "TRACK_INNER_NAVIGATION", path: location }, "*");
  }, [location]);

  const { dialog } = useDialogStore();

  useEffect(() => {
    if (dialog) {
      window.parent?.postMessage({ type: "POPUP_STATE", isOpen: true }, "*");
    } else {
      window.parent?.postMessage({ type: "POPUP_STATE", isOpen: false }, "*");
    }
  }, [dialog]);

  return <Outlet />;
};
